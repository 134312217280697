<template>
  <v-dialog
      v-model="open"
      max-width="400"
      overlay-color="primary"
      @close="$emit('close'); console.log('close')"
      @closed="$emit('close')"
  >
    <v-card v-if="!sent">
      <v-card-title class="justify-center text-uppercase primary--text font-weight-bold today-medium"
                    style="padding-top: 35px">
        DATOS DE CONTACTO
      </v-card-title>
      <div class="px-14 pb-6 mt-4">
        <v-form
            ref="form"
            lazy-validation
            @submit.prevent="sendContactData">

          <v-text-field
              type="text"
              v-model="full_name"
              :rules="[rules.required]"
              label="Nombre y apellido *"
              required
              outlined
              dense
              color="primary"
              class="input-field"></v-text-field>

          <v-text-field
              type="email"
              v-model="email"
              :rules="[rules.required]"
              label="Correo electrónico *"
              required
              outlined
              dense
              color="primary"
              class="input-field"></v-text-field>

          <v-text-field
              type="number"
              v-model="cell_phone"
              :rules="[rules.required, rules.counterMax, rules.counterMin]"
              label="Celular *"
              required
              outlined
              dense
              color="primary"
              class="input-field"></v-text-field>
          <v-checkbox v-model="terms" class="mt-0" color="primary">
            <template v-slot:label>
              <a href="https://vardiseguros.com.co/static/uploads/613b959d-eb39-4cad-b1e4-b9a9344194af.pdf"
                 class="text-caption terms-link font-weight-bold" color="primary" target="_blank">Enviando la
                información, estás aceptando los términos y condiciones, políticas de privacidad y tratamiento de datos
                personales</a>
            </template>
          </v-checkbox>

          <v-layout justify-center pt-6>
            <v-card-actions>
              <v-btn
                  style="min-width: 240px"
                  rounded
                  :loading="loading"
                  :disabled="loading || !terms"
                  color="primary"
                  class="px-14 text-capitalize vardi-button"
                  type="submit"
                  elevation="0">
                Enviar
              </v-btn>
            </v-card-actions>
          </v-layout>

        </v-form>
      </div>
    </v-card>
    <v-card v-else>
      <v-card-title class="justify-center text-uppercase primary--text font-weight-bold today-medium"
                    style="padding-top: 35px">
        <v-img max-height="50"
               class="my-4"
               contain
               src="@/assets/img/enviar.png"/>
        INFORMACIÓN ENVIADA
      </v-card-title>
      <div class="px-14 pb-6 mt-4">
        <p class="text-justify today-light line-height-1" style="font-size: 1.3em;">
          Hemos recibido tu información. Un
          asesor de VARDI Servicios
          Financieros se pondrá en contacto
          contigo en las próximas horas.
        </p>
        <v-layout justify-center pt-6>
          <v-card-actions>
            <v-btn
                style="min-width: 240px"
                rounded
                color="primary"
                class="px-14 text-capitalize vardi-button"
                type="submit"
                elevation="0"
                @click="$emit('close')">
              ACEPTAR
            </v-btn>
          </v-card-actions>
        </v-layout>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import NavBarPage from "@/components/Layouts/NavBarPage";
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";

export default {
  name: 'ContactData',
  components: {LeftSideImage, NavBarPage},
  props: ['dialog'],
  data() {
    return {
      open: this.dialog,
      full_name: '',
      email: '',
      cell_phone: '',
      terms: false,
      loading: false,
      rules: {
        required: value => !!value || 'Ingresa la información',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 digitos',
        counterMin: value => value.length == 10 || 'Cantidad minima 10 digitos'
      },
      snackbar: false,
      timeout: 3000,
      messageError: '',
      sent: false
    }
  },
  methods: {
    sendContactData() {
      let validate = this.$refs.form.validate();
      if (validate) {
        this.loading = true;
        api.post(constants.endPoints.storeContact, {
          full_name: this.full_name,
          email: this.email,
          cell_phone: this.cell_phone,
          terms: 1
        }, false)
            .then((response) => {
              this.loading = false;
              this.sent = true;
            }, (error) => {
              console.log('post', error);
              this.loading = false;
              this.snackbar = true;
            });

      }
    }
  },
  watch: {
    dialog(value) {
      this.open = value;
    },
    open(value) {
      if (!value) {
        this.$emit('close');
      }
    }
  }
}
</script>