<template>
  <div class="simulate-financing fill-height">
    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">
        <nav-bar-page/>
        <v-container>
          <v-row justify="center" class="mt-0">
            <v-row align="center" justify="center" class="mb-1">
              <v-col cols="12" md="10" class="pa-0">
                <div class="primary--text">
                  <h1 class="text-center today-regular home-title">
                    SIMULAR FINANCIACIÓN DE PÓLIZA
                  </h1>
                </div>
              </v-col>
              <v-col cols="12" md="10" class="pa-0">
                <h2 class="text-center primary--text today-light sub-title" style="font-weight: 100; line-height: 1;">
                  Simula la financiación de tu póliza y descubre las mejores opciones para proteger tu vehículo sin
                  comprometer tu presupuest
                </h2>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <v-form
                    ref="form"
                    lazy-validation>
                  <v-row>
                    <v-col cols="12" md="5">
                      <h2 class="primary--text today-regular">
                        Valor de la póliza
                      </h2>
                      <span class="grey--text text-sm-body-2 today-medium">Desde $ 100.000</span>
                      <currency-input
                          v-model.number="total_amount"
                          :options="{
                            locale: 'en-US',
                            currency: 'USD',
                            currencyDisplay: 'hidden',
                            hideCurrencySymbolOnFocus: true,
                            hideGroupingSeparatorOnFocus: false,
                            hideNegligibleDecimalDigitsOnFocus: false,
                            autoDecimalDigits: false,
                            autoSign: false,
                            useGrouping: true,
                            accountingSign: true
                          }"
                          @blur="newCalculateFee($event, 1)"
                          :rules="[rules.minValue, rules.maxValue]"/>
                    </v-col>
                    <v-col cols="12" md="5" offset-md="2">
                      <h2 class="primary--text today-regular">
                        Plazo de financiación
                      </h2>
                      <span class="grey--text text-sm-body-2 today-medium">De 2 a {{ this.maxFeeNumber }} meses</span>
                      <v-select
                          :items="items"
                          :rules="[v => !!v || 'Selecciona el campo']"
                          label="Cuotas"
                          required
                          outlined
                          single-line
                          color="primary"
                          v-model="fee_number"
                          v-on:change="newCalculateFee($event, 0)"
                          class="text-h5 select-vardi" />
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
            <v-col cols="10" class="pa-0">
              <v-card class="px-5 py-3"
                      color="grey lighten-4 rounded-lg"
                      outlined>
                <v-row>
                  <v-col cols="7" >
                    <h1 class="today-light primary--text mb-3">Tu cuota mensual sería de:</h1>
                    <h2 class="today-regular text-h3 mb-6">$ {{ formatCurrency(firstFeeValue) }}</h2>
                    <v-row>
                      <v-col cols="12" md="5">
                        <v-btn
                            color="white"
                            class="text-capitalize primary--text today-medium"
                            elevation="0"
                            outlined
                            rounded
                            block
                            @click="contactDialog = true">
                          CONTACTAR ASESOR
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-btn
                            color="primary"
                            class="text-capitalize today-medium"
                            elevation="0"
                            block
                            rounded
                            @click="dialog = !dialog">
                          INICIAR FINANCIACIÓN
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="5">
                    <h6 class="text-list today-light mb-1 primary--text">TASA FIJA MES VENCIDO</h6>
                    <h2 class="today-light text-title mb-1">$ {{ rate }} {{ rateType }}</h2>
                    <h6 class="text-list today-light mb-1 primary--text">TASA EFECTIVA ANUAL</h6>
                    <h2 class="today-light text-title">{{ insuranceRate }} E.A.</h2>
                  </v-col>
                  <v-col cols="12">
                    <p class="text-caption text-center primary--text line-height-1 p-0">
                      *La información presentada es un cálculo aproximado basado en la información suministrada y no constituyente de otorgamiento de crédito, para el cual se deberá realizar el
                      estudio de la solicitud de crédito acorde con las políticas de la aseguradora. Algunas condiciones de crédito podrán variar sin previo aviso
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <contact-data :dialog="contactDialog" @close="contactDialog = false"></contact-data>
    <onboarding-modal :dialog="dialog" @close="dialog = false"></onboarding-modal>
  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import OnboardingModal from "@/components/Layouts/OnboardingModal";
import NavBarPage from "@/components/Layouts/NavBarPage";
import CurrencyInput from '@/components/SimulatorCurrencyInput'
import {api} from "@/helpers/axios";
import {locals} from "@/helpers/locals";
import ContactData from "@/views/ContactData/ContactData.vue";


export default {
  name: 'SimulateFinancing',
  components: {ContactData, LeftSideImage, NavBarPage, OnboardingModal, CurrencyInput},
  data() {
    return {
      contactDialog: false,
      dialog: false,
      items: [2],
      firstFeeValue: 0,
      creditStudy: 0,
      insurance: 0,
      insuranceValue: 0,
      iva: 0,
      ivaValue: 0,
      othersFeeValue: 0,
      amortizedFee: 0,
      administration: 10000,
      fee_value: 0,
      fee_number: 2,
      typeFeeNumber: '',
      rate: 1.25,
      insuranceRate: 0,
      rateType: '',
      total_amount: 0,
      minFeeNumber: 0,
      maxFeeNumber: 0,
      minAmount: 0,
      maxAmount: 0,
      admin: 0,
      settings: [],
      rules: {
        required: value => !!value || 'Ingresa la información',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 digitos',
        counterMin: value => value.length === 10 || 'Cantidad minima 10 digitos',
        minValue: () => this.total_amount >= this.minAmount || 'Monto mínimo $ ' + this.formatCurrency(this.minAmount),
        maxValue: () => this.total_amount <= this.maxAmount || 'Monto maximo $ ' + this.formatCurrency(this.maxAmount)
      },
      snackbar: false
    }
  },
  created() {
    this.settings = JSON.parse(localStorage.getItem('settings'));
    this.dataInit();
  },
  methods: {
    dataInit() {
      this.settings.credit_config.forEach((value) => {
        switch (value.key) {
          case "type_fee_number":
            this.typeFeeNumber = value.value;
            break;
          case "min_fee_number":
            this.minFeeNumber = parseInt(value.value);
            break;
          case "max_fee_number":
            this.maxFeeNumber = parseInt(value.value);
            break;
          case "min_amount":
            this.minAmount = parseInt(value.value);
            break;
          case "max_amount":
            this.maxAmount = parseInt(value.value);
            break;
          case "insurance_rate":
            this.insuranceRate = value.value;
            break;
          case "rate":
            this.rate = parseFloat(value.value);
            break;
          case "rate_type":
            this.rateType = value.value;
            break;
          case "administration":
            this.administration = parseInt(value.value);
            break;
          case "iva":
            this.iva = parseFloat(value.value);
            break;
          case "credit_study":
            this.creditStudy = parseInt(value.value);
            break;
          case "credit_insurance":
            this.insurance = parseFloat(value.value);
            break;
          default:
            console.log("NO existe: ", value.value);
        }

      })

      for (let i = parseInt(this.minFeeNumber); i <= parseInt(this.maxFeeNumber); i++) {
        this.items.push(i);
      }
    },
    newCalculateFee(event, isTotalAmount) {
      console.log('newCalculateFee', event, isTotalAmount);
      if (isTotalAmount) {
        this.total_amount = parseInt(event, 10);
        this.snackbar = true;
      } else {
        this.fee_number = event;
      }
      this.valueFinance = parseInt(this.total_amount, 10);
      api.post('https://tefio-vardi.bio.credit/calcularcuota', {
        data: {
          fecha: "16-05-2023",
          valor: this.valueFinance,
          plazo: this.fee_number,
          tasa: this.insuranceRate,
          portafolio_id: 10040,
          idvardi: "1"
        }
      })
          .then(response => {

            console.log('calculo sigma', response);

            this.feeValue = response.data.plan_pagos[0].cuota

            // this.amortizedFee = this.feeValue;
            // this.firstFeeValue = this.amortizedFee;
            // this.othersFeeValue = this.amortizedFee;

            this.amortizedFee = this.feeValue;
            this.firstFeeValue = this.amortizedFee + this.creditStudy + this.insurance + this.ivaValue;
            this.othersFeeValue = this.amortizedFee + this.admin;


          }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if (status === 401) {
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
        }

      }.bind(this))


    },
    formatCurrency: function (value) {
      var formatter = new Intl.NumberFormat('es-ES', {
        style: 'decimal',
        minimumFractionDigits: 0
      });

      value = Math.round(value)
      return formatter.format(parseInt(value, 10));
    },
    validateData() {
      console.log(this.total_amount);
    }
  }
}
</script>
<style scoped>
h1{
  font-size: 1.8em !important;
}
h2{
  font-size: 1.4em !important;
  margin-block-end: 0 !important;
}

.text-caption {
  padding: 0 !important;
  margin-bottom: 10px !important;
}
</style>
